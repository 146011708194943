import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import FormModel from '@/models/Objects/FormModel';

@Component
export default class Breadcrumb extends Vue {
    private currentFormName: string = '';
    private currentWorkflowName: string = '';
    private previousForms: FormModel[] = [];

    protected created() {
        EventBus.$on('renderNewMessage', (display: boolean) => this.updateBreadcrumb());
        this.updateBreadcrumb();
    }

    protected updateBreadcrumb() {
        let formHistory: FormModel[] = this.$store.state.formHistory;
        // this.currentFormName = formHistory[formHistory.length - 1]?.name ?? '';
        // const firstThis = formHistory.findIndex((form) => form.name = this.currentFormName);
        formHistory = formHistory.filter((form) => form.sessionIdentifier === this.$store.state.sessionIdentifier);
        const unique: FormModel[] = [];
        for (const formHistoryItem of formHistory) {
            const existingIndex = unique.findIndex((form) => form.name === formHistoryItem.name);
            if (existingIndex >= 0) {
                unique.splice(existingIndex, 1);
            }
            unique.push(formHistoryItem);
        }
        this.previousForms = unique;
    }

    private editForm(form: FormModel) {
        const formData: LooseObject = {};
        form.formFields.forEach((formField) => formData[formField.name] = form.body[formField.name]);
        EventBus.$emit('editPreviousFormData', form.name, form.workflow, formData, 'edit', form.actionId);
    }
}
